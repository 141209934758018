import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { environment} from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './index/index.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { ProductsComponent } from './products/products.component';
import { BlogComponent } from './blog/blog.component';
import { BlogviewComponent } from './blogview/blogview.component';
import { RequestdemoComponent } from './requestdemo/requestdemo.component';
import { CareersComponent } from './careers/careers.component';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    SolutionsComponent,
    ProductsComponent,
    BlogComponent,
    BlogviewComponent,
    RequestdemoComponent,
    CareersComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
