import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { from } from 'rxjs';
import { IndexComponent } from './index/index.component';
import { SolutionsComponent } from './solutions/solutions.component';
import { ProductsComponent }   from './products/products.component';
import { BlogComponent} from './blog/blog.component';
import { BlogviewComponent} from './blogview/blogview.component';
import { RequestdemoComponent} from './requestdemo/requestdemo.component';
import { CareersComponent} from './careers/careers.component';

const routes: Routes = [
  { path: 'index', component: IndexComponent},
  { path: 'solution', component: SolutionsComponent},
  { path: 'products', component: ProductsComponent},
  { path: 'careers' , component: CareersComponent},
  { path: '**', component: IndexComponent}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
