<header class="u-align-right u-clearfix u-header u-sticky u-white u-header" id="sec-5cc0">
  <div class="u-clearfix u-sheet u-sheet-1">
    <a routerLink="#" class="u-image u-logo u-image-1" data-image-width="80" data-image-height="40">
      <img src="assets/LOGO.png" class="u-logo-image u-logo-image-1" data-image-width="64">
    </a>
    <nav class="u-align-right u-menu u-menu-dropdown u-offcanvas u-menu-1">
      <div class="menu-collapse" style="font-size: 0.875rem; letter-spacing: 0px; font-weight: 400;">
        <a class="u-border-2 u-border-active-custom-color-2 u-border-hover-custom-color-1 u-border-no-left u-border-no-right u-border-no-top u-button-style u-custom-left-right-menu-spacing u-custom-padding-bottom u-custom-top-bottom-menu-spacing u-nav-link u-text-active-custom-color-2 u-text-grey-90 u-text-hover-custom-color-1"
          routerLink="#">
          <svg>
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:routerLink="#menu-hamburger"></use>
          </svg>
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
              <symbol id="menu-hamburger" viewBox="0 0 16 16" style="width: 16px; height: 16px;">
                <rect y="1" width="16" height="2"></rect>
                <rect y="7" width="16" height="2"></rect>
                <rect y="13" width="16" height="2"></rect>
              </symbol>
            </defs>
          </svg>
        </a>
      </div>
      <div class="u-custom-menu u-nav-container">
        <ul class="u-nav u-spacing-30 u-unstyled u-nav-1">
          <li class="u-nav-item"><a
              class="u-border-2 u-border-active-custom-color-2 u-border-hover-custom-color-1 u-border-no-left u-border-no-right u-border-no-top u-button-style u-nav-link u-text-active-custom-color-2 u-text-grey-90 u-text-hover-custom-color-1"
              routerLink="index" style="padding: 6px 0px;">HOME</a>
          </li>
          <li class="u-nav-item"><a
              class="u-border-2 u-border-active-custom-color-2 u-border-hover-custom-color-1 u-border-no-left u-border-no-right u-border-no-top u-button-style u-nav-link u-text-active-custom-color-2 u-text-grey-90 u-text-hover-custom-color-1"
              routerLink="solution" style="padding: 6px 0px;">SOLUTIONS</a>
          </li>
          <li class="u-nav-item"><a
              class="u-border-2 u-border-active-custom-color-2 u-border-hover-custom-color-1 u-border-no-left u-border-no-right u-border-no-top u-button-style u-nav-link u-text-active-custom-color-2 u-text-grey-90 u-text-hover-custom-color-1"
              routerLink="products" style="padding: 6px 0px;">PRODUCTS</a>
          </li>
          <!-- <li class="u-nav-item"><a
              class="u-border-2 u-border-active-custom-color-2 u-border-hover-custom-color-1 u-border-no-left u-border-no-right u-border-no-top u-button-style u-nav-link u-text-active-custom-color-2 u-text-grey-90 u-text-hover-custom-color-1"
              routerLink="blog" style="padding: 6px 0px;">BLOG</a>
          </li> -->
         <!-- <li class="u-nav-item"><a
              class="u-border-2 u-border-active-custom-color-2 u-border-hover-custom-color-1 u-border-no-left u-border-no-right u-border-no-top u-button-style u-nav-link u-text-active-custom-color-2 u-text-grey-90 u-text-hover-custom-color-1"
              routerLink="blogview" style="padding: 6px 0px;">BLOGVIEW</a>
          </li> -->
          <!-- <li class="u-nav-item"><a
              class="u-border-2 u-border-active-custom-color-2 u-border-hover-custom-color-1 u-border-no-left u-border-no-right u-border-no-top u-button-style u-nav-link u-text-active-custom-color-2 u-text-grey-90 u-text-hover-custom-color-1"
              routerLink="requestdemo" style="padding: 6px 0px;">REQUEST DEMO</a>
          </li> -->

       <li class="u-nav-item"><a
              class="u-border-2 u-border-active-custom-color-2 u-border-hover-custom-color-1 u-border-no-left u-border-no-right u-border-no-top u-button-style u-nav-link u-text-active-custom-color-2 u-text-grey-90 u-text-hover-custom-color-1"
              routerLink="careers" style="padding: 6px 0px;">CAREER</a>
          </li>
        </ul>
      </div>
      <div class="u-custom-menu u-nav-container-collapse">
        <div class="u-black u-container-style u-inner-container-layout u-opacity u-opacity-95 u-sidenav">
          <div class="u-sidenav-overflow">
            <div class="u-menu-close"></div>
            <ul class="u-align-center u-nav u-popupmenu-items u-unstyled u-nav-2">
              <li class="u-nav-item"><a class="u-button-style u-nav-link" routerLink="index"
                  style="padding: 6px 0px;">HOME</a>
              </li>
              <li class="u-nav-item"><a class="u-button-style u-nav-link" routerLink="solution"
                  style="padding: 6px 0px;">SOLUTIONS</a>
              </li>
              <li class="u-nav-item"><a class="u-button-style u-nav-link" routerLink="products"
                  style="padding: 6px 0px;">PRODUCTS</a>
              </li>
              <!-- <li class="u-nav-item"><a class="u-button-style u-nav-link" routerLink="blog"
                  style="padding: 6px 0px;">BLOG</a>
              </li> -->
               <!-- <li class="u-nav-item"><a class="u-button-style u-nav-link" routerLink="blogview"
                  style="padding: 6px 0px;">BLOGVIEW</a>
              </li> -->
              <!-- <li class="u-nav-item"><a class="u-button-style u-nav-link" routerLink="requestdemo"
                  style="padding: 6px 0px;">REQUEST DEMO</a>
              </li> -->
              <li class="u-nav-item"><a class="u-button-style u-nav-link" routerLink="careers"
                  style="padding: 6px 0px;">CAREER</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="u-black u-menu-overlay u-opacity u-opacity-70"></div>
      </div>
    </nav>
    <h5 class="u-custom-font u-font-raleway u-text u-text-default u-text-1">CosmicAI</h5>
  </div>
</header>
<router-outlet></router-outlet>
<footer class="u-clearfix u-footer u-grey-80 u-footer" id="sec-4877">
  <div class="u-clearfix u-sheet u-sheet-1">
    <div
      class="u-clearfix u-expanded-width-lg u-expanded-width-md u-expanded-width-xl u-expanded-width-xs u-gutter-30 u-layout-wrap u-layout-wrap-1" style="padding-top: 20px; ">
      <div class="container">
        <div class="row">
          <div class="col-sm">
            <div class="u-block">
              <div class="u-block-container u-clearfix">
                <!--block_header-->
                <h4 class="u-block-header u-text u-text-1">
                  <!--block_header_content--> ABOUT COSMICAI
                  <!--/block_header_content-->
                </h4>
                <!--/block_header-->
                <!--block_content-->
                <div class="u-block-content u-text u-text-2 ft">
                  <ul>
                    <li><a class="u-text-hover-purple" routerLink="#">About Us</a></li>
                    <li> <a class="u-text-hover-purple" routerLink="#">Why Choose Us</a></li>
                    <li> <a class="u-text-hover-purple" routerLink="#">How we work</a></li>
                    <li> <a class="u-text-hover-purple" routerLink="careers">Career</a></li>
                    </ul>
                </div>
                <!--/block_content-->
              </div>
            </div>
          </div>
          <div class="col-sm">
            <div class="u-block">
              <div class="u-block-container u-clearfix">
                <!--block_header-->
                <h4 class="u-block-header u-text u-text-3">
                  <!--block_header_content--> SERVICE
                  <!--/block_header_content-->
                </h4>
                <!--/block_header-->
                <!--block_content-->
                <div class="u-block-content u-text u-text-4 ft">
                  <ul>
                    <li> <a class="u-text-hover-purple" routerLink="solution">ML Solutions</a></li>
                    <li> <a class="u-text-hover-purple" routerLink="#">Data Science</a></li>
                    <li> <a class="u-text-hover-purple" routerLink="solution">App Development</a></li>
                    <li> <a class="u-text-hover-purple" routerLink="solution">Web Development</a></li>
                    <li> <a class="u-text-hover-purple" routerLink="#">Blockchain Development</a></li>
                    <li> <a class="u-text-hover-purple" routerLink="solution">Chatbot Solution</a></li>
                    <li> <a class="u-text-hover-purple" routerLink="#">IOT</a></li>
                  </ul>
                </div>
                <!--/block_content-->
              </div>
            </div>
          </div>
          <div class="col-sm">
            <div class="u-block">
              <div class="u-block-container u-clearfix">
                <!--block_header-->
                <h4 class="u-block-header u-text u-text-5">
                  <!--block_header_content--> PRODUCTS
                  <!--/block_header_content-->
                </h4>
                <!--/block_header-->
                <!--block_content-->
                <div class="u-block-content u-text u-text-6 ft">
                  <ul>
                    <li> <a class="u-text-hover-purple" routerLink="#">MMDB</a></li>
                    <li> <a class="u-text-hover-purple" routerLink="#">RQ-AI</a></li>
                    <li> <a class="u-text-hover-purple" routerLink="#">IMG-CHAR2DOC</a></li>
                    <li> <a class="u-text-hover-purple" routerLink="#">Marketing</a></li>
                    <li> <a class="u-text-hover-purple" routerLink="#">BookAN</a></li>
                  </ul>
                </div>
                <!--/block_content-->
              </div>
            </div>
          </div>
          <div class="col-sm">
            <div class="u-block">
              <div class="u-block-container u-clearfix">
                <!--block_header-->
                <h4 class="u-block-header u-text u-text-5">
                  <!--block_header_content--> Technologies
                  <!--/block_header_content-->
                </h4>
                <!--/block_header-->
                <!--block_content-->
                <div class="u-block-content u-text u-text-6 ft">
                  <ul>
                    <li> <a class="u-text-hover-purple" routerLink="#">React</a></li>
                    <li> <a class="u-text-hover-purple" routerLink="#">Node JS</a></li>
                    <li> <a class="u-text-hover-purple" routerLink="#">Python</a></li>
                    <li> <a class="u-text-hover-purple" routerLink="#">.NET</a></li>
                    <li> <a class="u-text-hover-purple" routerLink="#">Angular</a></li>
                    <li> <a class="u-text-hover-purple" routerLink="#">many more..</a></li>
                  </ul>
                </div>
                <!--/block_content-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="u-social-icons u-spacing-10 u-social-icons-1">
      <a class="u-social-url" target="_blank" routerLink="https://www.facebook.com/CosmicAILab"><span
          class="u-icon u-icon-circle u-social-facebook u-social-type-logo u-icon-1"><svg class="u-svg-link"
            preserveAspectRatio="xMidYMin slice" viewBox="0 0 112 112" style="">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:routerLink="#svg-a889"></use>
          </svg><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
            xml:space="preserve" class="u-svg-content" viewBox="0 0 112 112" x="0px" y="0px" id="svg-a889">
            <path
              d="M75.5,28.8H65.4c-1.5,0-4,0.9-4,4.3v9.4h13.9l-1.5,15.8H61.4v45.1H42.8V58.3h-8.8V42.4h8.8V32.2 c0-7.4,3.4-18.8,18.8-18.8h13.8v15.4H75.5z">
            </path>
          </svg></span>
      </a>
      <a class="u-social-url" target="_blank" routerLink=""><span
          class="u-icon u-icon-circle u-social-twitter u-social-type-logo u-icon-2"><svg class="u-svg-link"
            preserveAspectRatio="xMidYMin slice" viewBox="0 0 112 112" style="">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:routerLink="#svg-6e7a"></use>
          </svg><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
            xml:space="preserve" class="u-svg-content" viewBox="0 0 112 112" x="0px" y="0px" id="svg-6e7a">
            <path
              d="M92.2,38.2c0,0.8,0,1.6,0,2.3c0,24.3-18.6,52.4-52.6,52.4c-10.6,0.1-20.2-2.9-28.5-8.2 c1.4,0.2,2.9,0.2,4.4,0.2c8.7,0,16.7-2.9,23-7.9c-8.1-0.2-14.9-5.5-17.3-12.8c1.1,0.2,2.4,0.2,3.4,0.2c1.6,0,3.3-0.2,4.8-0.7 c-8.4-1.6-14.9-9.2-14.9-18c0-0.2,0-0.2,0-0.2c2.5,1.4,5.4,2.2,8.4,2.3c-5-3.3-8.3-8.9-8.3-15.4c0-3.4,1-6.5,2.5-9.2 c9.1,11.1,22.7,18.5,38,19.2c-0.2-1.4-0.4-2.8-0.4-4.3c0.1-10,8.3-18.2,18.5-18.2c5.4,0,10.1,2.2,13.5,5.7c4.3-0.8,8.1-2.3,11.7-4.5 c-1.4,4.3-4.3,7.9-8.1,10.1c3.7-0.4,7.3-1.4,10.6-2.9C98.9,32.3,95.7,35.5,92.2,38.2z">
            </path>
          </svg></span>
      </a>
      <a class="u-social-url" target="_blank" routerLink="https://www.instagram.com/cosmicailab/"><span
          class="u-icon u-icon-circle u-social-instagram u-social-type-logo u-icon-3"><svg class="u-svg-link"
            preserveAspectRatio="xMidYMin slice" viewBox="0 0 112 112" style="">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:routerLink="#svg-ae23"></use>
          </svg><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
            xml:space="preserve" class="u-svg-content" viewBox="0 0 112 112" x="0px" y="0px" id="svg-ae23">
            <path
              d="M55.9,32.9c-12.8,0-23.2,10.4-23.2,23.2s10.4,23.2,23.2,23.2s23.2-10.4,23.2-23.2S68.7,32.9,55.9,32.9z M55.9,69.4c-7.4,0-13.3-6-13.3-13.3c-0.1-7.4,6-13.3,13.3-13.3s13.3,6,13.3,13.3C69.3,63.5,63.3,69.4,55.9,69.4z">
            </path>
            <path d="M79.7,26.8c-3,0-5.4,2.5-5.4,5.4s2.5,5.4,5.4,5.4c3,0,5.4-2.5,5.4-5.4S82.7,26.8,79.7,26.8z"></path>
            <path
              d="M78.2,11H33.5C21,11,10.8,21.3,10.8,33.7v44.7c0,12.6,10.2,22.8,22.7,22.8h44.7c12.6,0,22.7-10.2,22.7-22.7 V33.7C100.8,21.1,90.6,11,78.2,11z M91,78.4c0,7.1-5.8,12.8-12.8,12.8H33.5c-7.1,0-12.8-5.8-12.8-12.8V33.7 c0-7.1,5.8-12.8,12.8-12.8h44.7c7.1,0,12.8,5.8,12.8,12.8V78.4z">
            </path>
          </svg></span>
      </a>
      <a class="u-social-url" target="_blank" routerLink=""><span
          class="u-icon u-icon-circle u-social-google u-social-type-logo u-icon-4"><svg class="u-svg-link"
            preserveAspectRatio="xMidYMin slice" viewBox="0 0 112 112" style="">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:routerLink="#svg-ee9a"></use>
          </svg><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
            xml:space="preserve" class="u-svg-content" viewBox="0 0 112 112" x="0px" y="0px" id="svg-ee9a">
            <path
              d="M62.2,81.6c-8.6,11.2-24.6,14.4-37.6,10C10.9,87,0.8,73.2,1,58.5c-0.8-18,15.2-34.6,33.1-34.9 c9.2-0.8,18.2,2.7,25,8.6c-2.9,3.1-5.7,6.2-8.9,9.2c-6.2-3.8-13.5-6.5-20.6-4C18.1,40.7,11,54.2,15.4,65.6 c3.5,11.8,17.8,18.3,29.2,13.2c5.8-2.1,9.7-7.4,11.3-13.2c-6.6-0.1-13.2,0-20.1-0.3c0-3.9,0-7.9,0-11.9c11.2,0,22.2,0,33.3,0 C69.9,63.4,68.3,73.9,62.2,81.6z M110.9,63.7c-3.4,0-6.6,0-10,0c0,3.4,0,6.6,0,10c-3.4,0-6.6,0-10,0c0-3.4,0-6.6,0-10 c-3.4,0-6.6,0-10,0c0-3.4,0-6.6,0-10c3.4,0,6.6,0,10,0c0-3.4,0-6.6,0.1-10c3.4,0,6.6,0,10,0c0,3.4,0,6.6,0,10c3.4,0,6.6,0,10,0 C110.9,56.9,110.9,60.3,110.9,63.7z">
            </path>
          </svg></span>
      </a>
      <a class="u-social-url" target="_blank" routerLink=""><span
          class="u-icon u-icon-circle u-social-pinterest u-social-type-logo u-icon-5"><svg class="u-svg-link"
            preserveAspectRatio="xMidYMin slice" viewBox="0 0 112 112" style="">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:routerLink="#svg-d94c"></use>
          </svg><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
            xml:space="preserve" class="u-svg-content" viewBox="0 0 112 112" x="0px" y="0px" id="svg-d94c">
            <path
              d="M61.9,79.8c-5.5-0.3-7.8-3.1-12-5.8c-2.3,12.4-5.4,24.3-13.8,30.5c-2.6-18.7,3.8-32.8,6.9-47.7 c-5.1-8.7,0.7-26.2,11.5-21.9c13.5,5.4-11.6,32.3,5.1,35.7c17.6,3.5,24.7-30.5,13.8-41.4c-15.7-16.1-45.7-0.5-42,22.3 c0.9,5.6,6.7,7.2,2.3,15c-10.1-2.2-13-10.2-12.7-20.7c0.6-17.3,15.5-29.3,30.5-31.1c19-2.2,36.8,6.9,39.2,24.7 C93.4,59.5,82.3,81.3,61.9,79.8z">
            </path>
          </svg></span>
      </a>
    </div>
    <p class="u-align-left u-text u-text-9">©&nbsp;Copyright&nbsp;CosmicAI. Bangalore INDIA. All Rights Reserved</p>
  </div>
</footer>
