import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormArray,
  Validators
} from '@angular/forms';


@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.scss']
})
export class SolutionsComponent implements OnInit {


  constructor() {}



  disableAlert() {
    alert("The company is temporarily closed. All operations and business have been halted.");
  }

  ngOnInit(): void {

  }
}

