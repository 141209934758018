<section class="u-align-left-sm u-align-left-xs u-align-right-lg u-align-right-md u-align-right-xl u-clearfix u-section-1" id="carousel_1df3">
    <div class="u-clearfix u-sheet u-valign-middle u-sheet-1">
      <h1 class="u-custom-font u-font-oswald u-text u-text-palette-5-dark-2 u-text-1">Unlocking the potential of those who advance the world</h1>
      <div class="u-clearfix u-expanded-width u-gutter-30 u-layout-wrap u-layout-wrap-1">
        <div class="u-gutter-0 u-layout">
          <div class="u-layout-row">
            <div class="u-align-left u-container-style u-layout-cell u-left-cell u-size-17-lg u-size-17-xl u-size-19-sm u-size-19-xs u-size-60-md u-layout-cell-1">
              <div class="u-container-layout u-valign-middle u-container-layout-1">
                <h3 class="u-text u-text-2">Careers</h3>
                <p class="u-text u-text-3">Send your resume at hr&#64;cosmicailab.com</p>
              </div>
            </div>
            <div class="u-align-left u-container-style u-layout-cell u-size-16-sm u-size-16-xs u-size-18-lg u-size-18-xl u-size-60-md u-layout-cell-2">
              <div class="u-container-layout u-valign-middle u-container-layout-2">
                <img src="assets/photo1554325478ef74563e4c35.jpg" alt="" class="u-align-left u-expanded-width u-image u-image-1">
              </div>
            </div>
            <div class="u-align-left u-container-style u-image u-layout-cell u-right-cell u-size-25 u-size-60-md u-image-2" src="">
              <div class="u-container-layout u-valign-middle u-container-layout-3" src=""></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="u-align-left u-clearfix u-grey-5 u-section-2" id="carousel_460c">
    <div class="u-clearfix u-sheet u-sheet-1">
      <h2 class="u-text u-text-default u-text-1">Current NO Openings</h2>
      <!-- <div class="u-expanded-width u-list u-repeater u-list-1">
        <div class="u-align-left u-container-style u-list-item u-repeater-item u-white u-list-item-1">
          <div class="u-container-layout u-similar-container u-container-layout-1">
            <p class="u-text u-text-3">
              <br><b>Angular Developer</b>
              <br>
              <br>
              <br><b>Experience 0 years to 3 years</b>
              <br>
              <br><b>Job description</b>
              <br>The applicant should have experience in developing web-technologies like Angular, HTML, CSS, Jquery. Familiar with Data Science and ML concepts will be an added advantage.<br>
              <br><b>Required Candidate profile</b>
              <br>Successfully completed studies in BE/B.Tech/MCA or a Diploma holder in Computer Applications.<br>Experience with Angular and Firebase.<br>Very good English skills<br>
              <br>Role <a href="#"  class="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-1-base u-btn-1">Frontend Developer</a>
              <br>Industry Type&nbsp;<a href="#"  class="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-1-base u-btn-2">IT-Software</a>,<a href="#"  class="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-1-base u-btn-3">&nbsp;Software Services</a>
              <br>Functional Area&nbsp;<a href="#"  class="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-1-base u-btn-4">UI&amp;UX Developer</a>
              <br>Employment TypeFull Time, Permanent<br>
              <br>
              <br>Education<br>UG : B.E, B.Tech,&nbsp;<br>
              <br>Key Skills<br>
              <font class="u-text-palette-1-dark-1">HTML, CSS, JQUERY, Angular</font>
              <br>
            </p>
          </div>
        </div>
        <div class="u-align-left u-container-style u-list-item u-repeater-item u-video-cover u-white u-list-item-2">
          <div class="u-container-layout u-similar-container u-container-layout-2">
            <p class="u-text u-text-5">
              <br><b>Business Analyst</b>
              <br>
              <br>
              <br><b>Experience 0 years to 3 years</b>
              <br>
              <br><b>Job description</b>
              <br>Research market trends and analyze the customer's needs and identify changes that are needed. Definition of approaches to requirements acquisition.<br>Develop use cases and test scripts that contain test metrics. Technically oriented, analytical mindset<br>
              <br><b>Required Candidate profile</b>
              <br>Successfully completed studies in a business-related discipline<br>Experience in information technology and knowledge of all domains.<br>Very good English skills<br>
              <br>Role&nbsp;<a href="#"  class="u-active-none u-border-none u-btn u-button-style u-hover-none u-none u-text-palette-1-base u-btn-5">Business Analyst</a>
              <br>Industry Type&nbsp;<a href="#"  class="u-active-none u-border-none u-btn u-button-style u-hover-none u-none u-text-palette-1-base u-btn-6">IT-Software</a>,<a href="#"  class="u-active-none u-border-none u-btn u-button-style u-hover-none u-none u-text-palette-1-base u-btn-7">&nbsp;Software Services</a>
              <br>Functional Area&nbsp;<a href="#"  class="u-active-none u-border-none u-btn u-button-style u-hover-none u-none u-text-palette-1-base u-btn-8">Analytics &amp; Business Intelligence</a>
              <br>Employment TypeFull Time, Permanent<br>
              <br>
              <br>Education<br>UG :B.A in Any Specialization, B.B.A / B.M.S in Any&nbsp;<br>Specialization<br>PG :MBA/PGDM in Any Specialization<br>
              <br>Key Skills<br>
              <a href="#"  class="chip clickable u-active-none u-border-none u-btn u-button-style u-hover-none u-none u-text-palette-1-base u-btn-9">Product Promotion</a>
              <a href="#"  class="chip clickable u-active-none u-border-none u-btn u-button-style u-hover-none u-none u-text-palette-1-base u-btn-10">Sales</a>
              <a href="#"  class="chip clickable u-active-none u-border-none u-btn u-button-style u-hover-none u-none u-text-palette-1-base u-btn-11">Client Relationship Management</a>
              <a href="#"  class="chip clickable u-active-none u-border-none u-btn u-button-style u-hover-none u-none u-text-palette-1-base u-btn-12">New Market Development</a>
              <a href="#"  class="chip clickable u-active-none u-border-none u-btn u-button-style u-hover-none u-none u-text-palette-1-base u-btn-13">Business Development</a>
            </p>
          </div>
        </div>
      </div> -->
    </div>
  </section>
