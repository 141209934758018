import {
  Component,
  OnInit
} from '@angular/core';

import {
  FormControl,
  FormGroup,
  FormArray,
  Validators
} from '@angular/forms';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {


  constructor( ) {}




  ngOnInit(): void {

  }
}
